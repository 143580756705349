import { makeStyles } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import RigrafExpanded from '../fonts/rigraf/expanded/rigrafExpanded';
import Rigraf from '../fonts/rigraf/regular/rigraf';
import colors from '../styles/colors';
import MainLinkButton from '../elements/mainLinkButton';
import { Routes } from '../consts/routes';
import { descriptions, titles } from '../consts/seo';

const NotFoundPage = () => {
	const classes = useClasses();

	return (
		<Layout>
			<SEO title={titles.pageNotFound} description={descriptions.pageNotFound} />
			<div className={classes.container}>
				<h1 className={classes.header}>
					<span className={classes.titleSpan}>4</span>
					<StaticImage
						src="../images/brain-diagonal-top.png"
						alt=""
						loading="eager"
						placeholder="tracedSVG"
						layout="constrained"
						objectFit="contain"
						className={classes.brainImage}
					/>
					<span className={classes.titleSpan}>4</span>
				</h1>
				<p className={classes.description}>Page not found</p>
				<MainLinkButton to={Routes.home} text="Home" className={classes.button} />
			</div>
		</Layout>
	);
};

export default NotFoundPage;

const useClasses = makeStyles({
	container: {
		width: '100vw',
		minHeight: 'calc(100vh - 7.625em - 2px)',
		paddingTop: 'calc(7.625em + 2px)',
		backgroundColor: colors.buttonOutlineBlack,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		'@media (max-width: 599px)': {
			minHeight: 'calc(100vh - 3.75em - 2px)',
			paddingTop: 'calc(3.75em + 2px)',
		},
	},
	header: {
		margin: 0,
	},
	titleSpan: {
		color: colors.white,
		fontSize: '280px',
		lineHeight: '280px',
		fontFamily: Rigraf.semiBold,
		'@media (max-width: 599px)': {
			fontSize: '120px',
			lineHeight: '120px',
		},
	},
	description: {
		fontSize: '52px',
		lineHeight: '52px',
		fontFamily: RigrafExpanded.medium,
		margin: 0,
		color: colors.white,
		'@media (max-width: 599px)': {
			fontSize: '28px',
			lineHeight: '28px',
		},
	},
	brainImage: {
		'@media (max-width: 599px)': {
			maxWidth: '95px',
			maxHeight: '95px',
		},
	},
	button: {
		marginTop: '4em',
		'&:after': {
			border: `2px solid ${colors.white}`,
		},
	},
});
